import apiClient from "./http-common";
import Deal from "@/state/entities/deal";

class DealService {
  getAll () {
    return apiClient.get("/deals");
  }
  activeDeals() {
    return Deal.api().fetch({
      'status': 'active',
      'take': 25,
      'order_field': 'created_at'
    });
  }

  portfolioAssets() {
    return this.deals({
      'status': 'active',
      'take': 50,
      'order_field': 'created_at',
      'market_type': 'spot'
    });
  }

  lastClosedDeals(amount) {
    return this.deals({
      'status': 'completed',
      'take': amount,
      'order_field': 'closed_at'
    });
  }

  dealById(id) {
    return apiClient.get("/deals/" + id + "/view")
  }
  close(id) {
    return apiClient.post("/deals/" + id + "/close")
  }
  updateSettings(id, settings) {
    return apiClient.patch("/deals/" + id, settings);
  }

  deals(criteria = null) {
    let requestUrl = '/deals?order=DESC';

    if (criteria) {
      for( let key in criteria ) {
        let value = criteria[key];
        if(value)
         requestUrl += '&' + key + '=' + value;
      }
    }

    return apiClient.get(requestUrl);
  }
}

export default new DealService();
